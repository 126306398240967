import { useState } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"

const PaymentSchema = Yup.object().shape({
  ref: Yup.string()
    .matches(/^(?:[a-zA-Z]{2}\d{5}|\d{6})$/, "Format invalide")
    .required(),
  prenom: Yup.string().min(2).required(),
  nom: Yup.string().min(2).required(),
  telephone: Yup.string().required(),
  email: Yup.string().email().required(),
  adresse: Yup.string().required().min(5).required(),
  adresse2: Yup.string(),
  cp: Yup.string().required().min(5).max(5),
  ville: Yup.string().min(2).required(),
  pays: Yup.string().min(2).required(),
  montant: Yup.number().required(),
})

const PaymentForm = () => {
  const [sent, setSent] = useState(false)
  const [paymentData, setPaymentData] = useState({})

  const getIsoDateTime = () => {
    const date = new Date()
    const tzOffset = -date.getTimezoneOffset() * 60 // Convertir en secondes
    let sign = tzOffset >= 0 ? "+" : "-"
    let offset = Math.abs(tzOffset)
    let hours = Math.floor(offset / 3600)
      .toString()
      .padStart(2, "0")
    let minutes = ((offset % 3600) / 60).toString().padStart(2, "0")
    return `${date.toISOString().split(".")[0]}${sign}${hours}:${minutes}`
  }

  const getPBX = (first_name, last_name, address1, address2, zipcode, city, contry) => {
    return `<?xml version="1.0" encoding="utf-8"?><?xml version=\"1.0\" encoding=\"utf-8\"?><Billing><Address><FirstName>${first_name}</FirstName><LastName>${last_name}</LastName><Address1>${address1}</Address1><Address2>${address2}</Address2><ZipCode>${zipcode}</ZipCode><City>${city}</City><CountryCode>250</CountryCode></Address></Billing>`
  }

  const escapeHtml = (text) => {
    var map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    }

    return text.replace(/[&<>"']/g, function (m) {
      return map[m]
    })
  }

  return (
    <div className="flex flex-col justify-between w-full">
      <Formik
        initialValues={{
          ref: "",
          prenom: "",
          nom: "",
          telephone: "",
          email: "",
          adresse: "",
          adresse2: "",
          cp: "",
          ville: "",
          pays: "France",
          montant: "",
        }}
        validationSchema={PaymentSchema}
        // onSubmit={async (values) => {
        //   try {
        //     // alert(JSON.stringify(values, null, 2))
        //     // setPaymentData(values)
        //     // setSent(true)
        //     // const response = await fetch("/wp-content/themes/dpv-huissiers/api/sendMessage.php", {
        //     //   method: "POST",
        //     //   headers: {
        //     //     "Content-Type": "application/json",
        //     //   },
        //     //   body: JSON.stringify(values),
        //     // })
        //     // const text = await response.text()
        //     // console.log(text)
        //     // if (response.ok) {
        //     //   setSent(true)
        //     // } else {
        //     //   setSent(false)
        //     //   console.error("Une erreur est survenue lors de l'envoi du message.")
        //     // }
        //   } catch (error) {
        //     setSent(false)
        //     console.error("Une erreur est survenue : ", error)
        //   }
        // }}
      >
        {(props) => {
          const { touched, errors, isSubmitting, handleSubmit } = props
          return (
            <>
              <div className={`${sent ? "hidden" : ""} grow shrink mt-20`}>
                <form className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6" method="POST" action="/paiement-en-ligne-formulaire/">
                  <div className="space-y-6 col-span-2 md:col-span-1">
                    <Field
                      id="ref"
                      name="ref"
                      placeholder="Référence dossier*"
                      disabled={sent}
                      className={`${errors.ref && touched.ref ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="prenom"
                      name="prenom"
                      placeholder="Prénom*"
                      disabled={sent}
                      className={`${errors.prenom && touched.prenom ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="nom"
                      name="nom"
                      placeholder="Nom*"
                      disabled={sent}
                      className={`${errors.nom && touched.nom ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="telephone"
                      name="telephone"
                      placeholder="Téléphone*"
                      disabled={sent}
                      className={`${errors.telephone && touched.telephone ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="email"
                      name="email"
                      placeholder="E-mail*"
                      disabled={sent}
                      className={`${errors.email && touched.email ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                  </div>
                  <div className="space-y-6 col-span-2 md:col-span-1">
                    <Field
                      id="adresse"
                      name="adresse"
                      placeholder="Adresse*"
                      disabled={sent}
                      className={`${errors.adresse && touched.adresse ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="adresse2"
                      name="adresse2"
                      placeholder="Complément d'adresse"
                      disabled={sent}
                      className={`${errors.adresse2 && touched.adresse2 ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="cp"
                      name="cp"
                      placeholder="Code postal"
                      disabled={sent}
                      className={`${errors.cp && touched.cp ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="ville"
                      name="ville"
                      placeholder="Ville*"
                      disabled={sent}
                      className={`${errors.ville && touched.ville ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                    <Field
                      id="pays"
                      name="pays"
                      placeholder="Pays*"
                      disabled={sent}
                      className={`${errors.pays && touched.pays ? "border-red-500" : "border-stone-400"} ${
                        sent ? "bg-gray-200" : ""
                      } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50`}
                    />
                  </div>
                  <Field
                    id="montant"
                    name="montant"
                    type="number"
                    step=".01"
                    lang="fr"
                    placeholder="Montant à régler (en €)"
                    disabled={sent}
                    className={`${errors.nom && touched.nom ? "border-red-500" : "border-stone-400"} ${
                      sent ? "bg-gray-200" : ""
                    } font-sans border rounded-full px-6 py-4 items-center justify-center flex w-full focus:outline-none focus:ring-black/50 focus:border-stone-400/50 col-span-2 font-bold`}
                  />
                  <Field id="form_id" name="form_id" type="hidden" disabled={sent} value="payment_step2" />
                  <div className="col-span-2">
                    {!sent && (
                      <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
                        <div className="text-xs italic text-center sm:text-right order-1 sm:order-0">
                          En cliquant sur envoyer, vos données seront envoyées vers le système bancaire. Le paiement ne sera finalisé qu'une fois vos coordonnées bancaires saisies et validées.
                        </div>
                        <button
                          type="submit"
                          className={`${
                            !props.isValid || isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:bg-[#BFE2FF]/50 hover:border-[#BFE2FF]/50 cursor-pointer"
                          } inline-flex items-center justify-center font-bold border rounded-full border-[#BFE2FF] px-10 py-4 bg-[#BFE2FF] order-0 order-1`}
                          disabled={isSubmitting}>
                          {isSubmitting && (
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          )}
                          Envoyer
                        </button>
                      </div>
                    )}
                    {sent && (
                      <div className="flex items-center justify-start gap-3 text-green-600">
                        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                          <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                            <path d="m4 8l2.05 1.64a.48.48 0 0 0 .4.1a.5.5 0 0 0 .34-.24L10 4" />
                            <circle cx="7" cy="7" r="6.5" />
                          </g>
                        </svg>
                        <p>Votre paiement continue dans un nouvel onglet !</p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className={`${sent ? "" : "hidden"} grow shrink mt-20`}>
                <form className="flex flex-col" method="POST" action="https://tpeweb.e-transactions.fr/php/">
                  <input type="hidden" name="PBX_SITE" value="2934563" />
                  <input type="hidden" name="PBX_RANG" value="001" />
                  <input type="hidden" name="PBX_IDENTIFIANT" value="38011701" />
                  <input type="hidden" name="PBX_TOTAL" value={paymentData.montant * 100} />
                  <input type="hidden" name="PBX_DEVISE" value="978" />
                  <input type="hidden" name="PBX_CMD" value={paymentData.ref} />
                  <input type="hidden" name="PBX_PORTEUR" value={paymentData.email} />
                  <input type="hidden" name="PBX_REPONDRE_A" value="https://www.dpv-huissiers.com/up2pay" />
                  <input type="hidden" name="PBX_RETOUR" value="Mt:M;Ref:R;Auto:A;Erreur:E" />
                  <input type="hidden" name="PBX_EFFECTUE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=accepte" />
                  <input type="hidden" name="PBX_ANNULE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=annule" />
                  <input type="hidden" name="PBX_REFUSE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=refuse" />
                  <input type="hidden" name="PBX_HASH" value="SHA512" />
                  <input type="hidden" name="PBX_TIME" value={getIsoDateTime()} />
                  <input type="hidden" name="PBX_SHOPPINGCART" value='<?xml version="1.0" encoding="utf-8"?><shoppingcart><total><totalQuantity>1</totalQuantity></total></shoppingcart>' />
                  <input type="hidden" name="PBX_BILLING" value={getPBX(paymentData.prenom, paymentData.nom, paymentData.adresse, paymentData.adresse2, paymentData.cp, paymentData.ville)} />
                  <input type="hidden" name="PBX_HMAC" value="392ABAB4AB97F2DCBCB64FBC0C987D22BE4356BDD4A66AF9FA12DD5E63F66C53A331CD7A49B993E9EE00C3E064FFEB2030C3B5F7906EBF2FCBB6A574EBFC781F" />
                  <input type="submit" class="button2" value="Accéder au paiement sécurisé" />
                </form>
                <span onClick={() => setSent(false)}>Modifier la saisie</span>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default PaymentForm

/*

<form method="POST" action="https://tpeweb.e-transactions.fr/php/"> 
  <input type="hidden" name="PBX_SITE" value="2934563">
  <input type="hidden" name="PBX_RANG" value="001">
  <input type="hidden" name="PBX_IDENTIFIANT" value="38011701">
  <input type="hidden" name="PBX_TOTAL" value="100">
  <input type="hidden" name="PBX_DEVISE" value="978">
  <input type="hidden" name="PBX_CMD" value="Test">
  <input type="hidden" name="PBX_PORTEUR" value="william.ode@gmail.com">
  <input type="hidden" name="PBX_REPONDRE_A" value="https://www.dpv-huissiers.com/up2pay">
  <input type="hidden" name="PBX_RETOUR" value="Mt:M;Ref:R;Auto:A;Erreur:E">
  <input type="hidden" name="PBX_EFFECTUE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=accepte">
  <input type="hidden" name="PBX_ANNULE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=annule">
  <input type="hidden" name="PBX_REFUSE" value="https://www.dpv-huissiers.com/paiement-en-ligne-formulaire?r=refuse">
  <input type="hidden" name="PBX_HASH" value="SHA512"> 
  <input type="hidden" name="PBX_TIME" value="2023-09-26T15:12:01+02:00">
  <input type="hidden" name="PBX_SHOPPINGCART" value="<?xml version=&quot;1.0&quot; encoding=&quot;utf-8&quot;?><shoppingcart><total><totalQuantity>1</totalQuantity></total></shoppingcart>">
  <input type="hidden" name="PBX_BILLING" value="<?xml version=&quot;1.0&quot; encoding=&quot;utf-8&quot;?><Billing><Address><FirstName>Ode</FirstName><LastName>William</LastName><Address1>82 avenue Maréchal de Saxe</Address1><Address2>Complément adresse</Address2><ZipCode>69003</ZipCode><City>Lyon</City><CountryCode>250</CountryCode></Address></Billing>"> 
  <input type="hidden" name="PBX_HMAC" value="EEE6D40FAFCFD20DDFB54B1A606F0B498EC64B1BC035FCFD4BBA79FC844C8CEE768808322B67A2D836FE64EDC2978535D43D96368FD161EC6724ADA2DF9A8B12"> <input type="submit" class="button2" value="Accéder au paiement sécurisé"> 
</form>
https://www.paybox.com/espace-integrateur-documentation/la-solution-paybox-system/appel-page-paiement/
*/
